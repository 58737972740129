<template>
    <div>
        <pull-to :top-load-method="refresh" :top-config="TOP_DEFAULT_CONFIG" :is-bottom-bounce="false"
            :is-top-bounce="on_top">
            <template slot="top-block" slot-scope="props">
                <div class="top-load-wrapper text-center w-100">
                    <b-spinner v-if="props.state === 'trigger'" label="Loading..." />
                    <span v-else>{{ props.stateText }}</span>
                </div>
            </template>
            <div v-show="isUpdate || isLoading" id="bgLoader">
                <div class="loader" />
            </div>
            <b-card v-if="appData" no-body class="card-employee-task">
                <b-card-header>
                    <b-card-title class="w-100 d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <b-media vertical-align="center">
                                <template #aside>
                                    <b-avatar rounded="sm" size="32" :src="backend_url+appData.icon"
                                        :text="avatarText(appData.name)" :variant="`light-secondary`" />
                                </template>
                            </b-media>
                            <div class="info">
                                <h5 class="mb-0">
                                    {{ appData.name }}
                                </h5>
                            </div>
                        </div>
                        <div class="d-flex">
                            <social-icons v-if="appData.has_android" :social-type="'android'" :still="'mr-50'"
                                :size="'16'" />
                            <social-icons v-if="appData.has_ios" :social-type="'ios'" :size="'17'" />
                        </div>
                    </b-card-title>
                </b-card-header>
                <b-card-body>
                    <b-row v-if="appVersions && appVersions.length">
                        <b-col cols="12" md="12">
                            <h6 class="mt-1 mb-1">
                                Versions
                            </h6>
                        </b-col>
                        <b-col v-for="(build,index) in appVersions" :key="index" cols="12" md="6">
                            <div class="app-version">
                                <b-link v-b-tooltip.html.hover.top="'Download'"
                                    class="d-flex justify-content-between align-items-center"
                                    @click="downloadVersion(build)">
                                    <b-media no-body class="align-items-center">
                                        <b-media-aside vertical-align="center" class="mr-75">
                                            <social-icons :social-type="build.os"
                                                :size="build.os=='android'?'21':'24'" />
                                        </b-media-aside>
                                        <b-media-body class="my-auto">
                                            <h6 class="mb-0">
                                                <span class="font-w-400"> Version:</span> {{ build.version
                                                }} - #{{ build.id }}
                                            </h6>
                                            <small class="text-muted">{{ dateFormat(build.created_at) }}</small>
                                        </b-media-body>
                                    </b-media>
                                    <div class="d-flex align-items-center">
                                        <small class="text-muted mr-75 d-flex">
                                            <b-badge v-if="build.debug == 0" variant="light-success">Release
                                            </b-badge>
                                            <b-badge v-else variant="light-warning">Debug</b-badge>
                                            <b-badge v-if="build.aab == 1" variant="light-success ml-25">aab</b-badge>
                                            <b-badge :variant="build.downloaded?'light-success':'light-secondary'"
                                                class="d-inline-flex justify-content-center align-items-center ml-25">
                                                <feather-icon icon="DownloadIcon" size="7" class="mr-25" />
                                                {{ convertMb(build.size) }}
                                                <feather-icon icon="UserIcon" size="7" class="ml-25 mr-25" />
                                                {{build.download_count}}
                                            </b-badge>
                                        </small>
                                    </div>
                                </b-link>
                            </div>
                            <hr>
                        </b-col>

                    </b-row>
                    <b-row v-else-if="!appVersions.length && !isUpdate && searchControl">
                        <b-col>
                            <div class="w-100 text-center mt-1">
                                <h4 class="mb-1">
                                    Version not found 🚀
                                </h4>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
                <div v-if="!searchControl && totalPages >= page" v-observe-visibility="loadMoreVersions"
                    class="d-flex justify-content-center w-100 mb-1">
                    <b-spinner label="Loading..." />
                </div>
            </b-card>
        </pull-to>
    </div>
</template>

<script>
import {
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BRow, BCol, BLink, BBadge, VBTooltip, BSpinner
} from 'bootstrap-vue'
import useAppApi from '@/composables/useAppApi'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import SocialIcons from '@/components/SocialIcons.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import PullTo from 'vue-pull-to'

export default {
    components: {
        BCard,
        BCardTitle,
        BCardHeader,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        SocialIcons,
        BRow,
        BCol,
        BLink,
        BBadge,
        VBTooltip,
        ToastificationContent,
        BSpinner,
        PullTo
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    async mounted() {
        window.addEventListener("scroll", () => { this.on_top = window.pageYOffset <= 50 })
        store.dispatch('appStore/setStatus', true)
        store.dispatch('appStore/incPage', 0)
        this.deviceRead()
        await this.fetchApp(router.currentRoute.params.app)
        this.loadMoreVersions()
    },
    computed: {
        ...mapState('appStore', ['appVersions', 'isUpdate', 'searchControl', 'page', 'totalPages']),
    },
    data() {
        return {
            backend_url: process.env.VUE_APP_BACKEND_URL,
            apiUrl: process.env.VUE_APP_API_URL,
            perPage: 30,
            device: null,
            on_top: true,
        }
    },
    methods: {
        async refresh(loaded) {
            if(this.isLoading){
                loaded('done')
                return
            }
            await store.dispatch('appStore/resetVersions')
            await store.dispatch('appStore/incPage', 0)
            this.loadMoreVersions(loaded)
        },
        downloadVersion(build) {
            this.$store.dispatch('appStore/recordDownloadUser', { id: build.artifact_id }).then(response => {
                if (build.os === 'android') {
                    this.isLoading = true
                    this.$store
                        .dispatch('appStore/downloadAndroidBuild', {
                            id: build.id,
                        })
                        .then(response => {
                            window.location.assign(response.data.data)
                            this.isLoading = false
                            build.downloaded = true
                            build.download_count += 1
                        })
                        .catch(error => {
                            this.isLoading = false
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Not found.',
                                    icon: 'AlertTriangleIcon',
                                    variant: 'info',
                                    text: 'If you think there is an error, please contact the admin.',
                                },
                            })
                        })
                } else if (build.os === 'ios') {
                    document.location.href = `itms-services://?action=download-manifest&url=${process.env.VUE_APP_API_URL}artifact/ios/${build.token}.plist`
                    this.isLoading = true
                    setTimeout(() => {
                        this.isLoading = false
                        build.downloaded = true
                        build.download_count += 1
                    }, 4000);
                }
            })

        },
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        convertMb(x) {
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            let l = 0; let
                n = parseInt(x, 10) || 0
            while (n >= 1024 && ++l) {
                n /= 1024
            }
            return (`${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`)
        },
        deviceRead() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera
            if (/android/i.test(userAgent)) {
                this.device = 'android'
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                this.device = 'ios'
            }
        },
        async loadMoreVersions(loaded) {
            if(this.isLoading){
                return
            }
            this.isLoading = true;
            await store.dispatch('appStore/incPage', this.page + 1)
            const {data} = await store.dispatch('appStore/fetchVersions', {
                            id: this.$router.currentRoute.params.app,
                            os: this.device,
                            perPage: this.perPage,
                            page: this.page,
                        })
            store.dispatch('appStore/resetInfinite', data.pagination.totalPages <= this.page)
            this.isLoading = false;
            if (typeof(loaded) == 'function') loaded('done')
        },
    },
    setup() {
        const {
            TOP_DEFAULT_CONFIG,
            fetchApp,
            isLoading,
            appData,
        } = useAppApi()

        return {
            TOP_DEFAULT_CONFIG,
            fetchApp,
            isLoading,
            avatarText,
            appData,
        }
    },
}
</script>

<style>
.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #f9b81c;
    width: 32px;
    height: 32px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 999;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.mr-5px {
    margin-right: 5px;
}

.app-version {
    padding-top: 5px;
    padding-bottom: 5px;
}

.font-w-400 {
    font-weight: 400 !important;
}

#bgLoader {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    background: #23232342;
    z-index: 9999999;
}
</style>
